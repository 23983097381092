<template>
  <div class="container-fluid">
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <h2>{{ $t('views.client.import.steps.step-2.general.title') }}</h2>
          <div class="d-flex steps-control">
            <button @click="$emit('on:prev-step')">
              {{ $t('views.client.import.steps.prev') }}
            </button>
            <button @click="nextStep">
              {{ $t('views.client.import.steps.next') }}
            </button>
          </div>
        </div>
        <div class="form">
          <h3>{{ $t('views.client.import.steps.step-2.general.subscriptions.title') }}</h3>
          <h4>{{ $t('views.client.import.steps.step-2.general.subscriptions.subtitle') }}</h4>
          <table class="custom-table">
            <tr>
              <th>{{ $t('views.client.import.steps.step-2.general.file') }}</th>
              <th></th>
              <th>{{ $t('views.client.import.steps.step-2.general.subscriptions.correspondant') }}</th>
            </tr>
            <tr
              v-for="(fileSub, i) in fileSubscriptionPlans"
              :key="i"
              :class="{ error: fileSub.error }"
            >
              <td>{{ fileSub.key }}</td>
              <td>=></td>
              <td>
                <v-select
                  :class="'background-light-blue-inputs'"
                  v-model="fileSub.assigned"
                  :options="subscriptionPlans"
                  :reduce="sub => sub.value"
                  label="text"
                  @input="fileSub.error = false"
                >
                  <template #no-options="{}">
                    {{ $t('general.actions.no-result') }}
                  </template>
                </v-select>
              </td>
            </tr>
          </table>
        </div>
        <div class="form">
          <h3>{{ $t('views.client.import.steps.step-2.general.tokens.title') }}</h3>
          <h4>{{ $t('views.client.import.steps.step-2.general.tokens.subtitle') }}</h4>
          <table class="custom-table">
            <tr>
              <th>{{ $t('views.client.import.steps.step-2.general.file') }}</th>
              <th></th>
              <th>{{ $t('views.client.import.steps.step-2.general.tokens.correspondant') }}</th>
            </tr>
            <tr
              v-for="(fileToken, i) in filePaymentTokens"
              :key="i"
              :class="{ error: fileToken.error }"
            >
              <td>{{ fileToken.key }}</td>
              <td>=></td>
              <td>
                <v-select
                  :class="'background-light-blue-inputs'"
                  v-model="fileToken.assigned"
                  :options="paymentTokens"
                  :reduce="token => token.value"
                  label="text"
                  @input="fileToken.error = false"
                >
                  <template #no-options="{}">
                    {{ $t('general.actions.no-result') }}
                  </template>
                </v-select>
              </td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import 'vue-select/dist/vue-select.css';
import { getSubscriptionPlans } from '@api/doinsport/services/subscription-plans/subscription-plans.api'
import { getClubPaymentTokens } from '@api/doinsport/services/payment-token/payment-token.api'

export default {
  components: {
    VSelect: () => import('vue-select'),
  },
  data: () => ({
    subscriptionPlans: [],
    paymentTokens: [],
    filePaymentTokens: [],
    fileSubscriptionPlans: [],
  }),
  props: {
    tableFields: {
      type: Object,
      default: []
    },
    items: {
      type: Object,
      default: []
    },
    subscriptions: {
      type: Object,
      default: []
    },
    tokens: {
      type: Object,
      default: []
    }
  },
  computed: {
  },
  methods: {
    getClubInfos() {
      this.subscriptionPlans = [];
      getSubscriptionPlans().then(response => {
        response.data['hydra:member'].forEach(sub => {
          sub.prices.forEach((price) => {
            this.subscriptionPlans.push({
              text: `${sub.name} - ${price.label}`,
              value: price.id,
            })
          })
        })
      });

      this.paymentTokens = [];
      getClubPaymentTokens().then(response => {
        response.data['hydra:member'].forEach(el => {
          this.paymentTokens.push({
            text: el.name,
            value: el.id
          })
        })
      })
    },
    findSub(key) {
      const sub = this.subscriptions.find(el => el.key === key)
      if (sub) {
        return sub.assigned
      }
      return null
    },
    findToken(key) {
      const token = this.tokens.find(el => el.key === key)
      if (token) {
        return token.assigned
      }
      return null
    },
    getFileInfos() {
      this.filePaymentTokens = [];
      this.fileSubscriptionPlans = [];

      this.items.forEach(item => {
        if (item.payment_token_id && item.payment_token_id.trim().length > 0) {
          if (this.filePaymentTokens.findIndex(el => el.key === item.payment_token_id) > -1) {
            return
          }
          this.filePaymentTokens.push({
            key: item.payment_token_id,
            assigned: this.findToken(item.payment_token_id),
            error: false,
          });
        }
        if (item.subscription_card_plan_price_id && item.subscription_card_plan_price_id.trim().length > 0) {
          if (this.fileSubscriptionPlans.findIndex(el => el.key === item.subscription_card_plan_price_id) > -1) {
            return
          }
          this.fileSubscriptionPlans.push({
            key: item.subscription_card_plan_price_id,
            assigned: this.findSub(item.subscription_card_plan_price_id),
            error: false,
          });
        }
      })

      if (this.filePaymentTokens.length === 0 && this.fileSubscriptionPlans.length === 0) {
        this.nextStep(null, true)
      }
    },
    nextStep(e, skip = false) {
      let error = false;

      if (!skip) {
        this.filePaymentTokens.forEach(token => {
          if (token.assigned === null) {
            token.error = true
            error = true;
          } else {
            token.name = this.paymentTokens.find(el => el.value === token.assigned).text;
          }
        })
        this.fileSubscriptionPlans.forEach(sub => {
          if (sub.assigned === null) {
            sub.error = true
            error = true;
          } else {
            sub.name = this.subscriptionPlans.find(el => el.value === sub.assigned).text
          }
        })
      }

      if (error) {
        this.$flash(null, this.$t('views.client.import.steps.step-2.general.error'));
        return;
      }

      this.$emit('on:next-step', this.fileSubscriptionPlans, this.filePaymentTokens, skip);
    }
  },
  created() {
    this.getFileInfos()
    this.getClubInfos()
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/table/_custom-table.scss";
@import "@lazy/client/import/_client-menu.scss";
@import "@lazy/client/import/_client-step2.scss";
</style>
